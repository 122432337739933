import merge from "deepmerge";

const base = {
      appFullName: "Node Extension Special Instructions",
      appName: "NodeExtSpclInstrcn",
      description: "Node Extension Special Instructions",
      isProd: false,
      oAuth: {
        clientId: "nike.gfls.node-ext-spcl-intrcn",
        redirectUri: window.location.origin,
        userInfo:
            "https://nike-qa.oktapreview.com/oauth2/ausa0mcornpZLi0C40h7/v1/userinfo",
        scopes: [
          "fulfillment:node.ext.spcl.intrcn::create: fulfillment:node.ext.spcl.intrcn::read: fulfillment:node.ext.spcl.intrcn::update: fulfillment:node.ext.spcl.intrcn::delete: fulfillment:node.ext.spcl.intrcn::execute: fulfillment:node.fr.preprocessor::read: fulfillment:node.fr.preprocessor::create: fulfillment:node.fr.preprocessor::delete: node_management:node.ext.location.mgmt::create: node_management:node.ext.location.mgmt::read: node_management:node.ext.location.mgmt::delete: node_management:node.ext.loading.group::update: node_management:node.ext.loading.group::read: profile openid email",
        ],
      },
    },
    dev = merge(base, {
      //"http://localhost:9000",
      specialInstrcnApiUrl:
          "https://node-extn-spcl-instrcn-request-svc-dev.nodemanagement-test.nikecloud.com",
      customerApi:
          "https://node-fr-preprocessor-svc-dev.nodemanagement-test.nikecloud.com",
      carrierHubApi:
          "https://node-fr-preprocessor-svc-dev.nodemanagement-test.nikecloud.com",
      locationMgmtApi:
          "https://node-ext-locn-mgmt-request-svc-dev-eu.nodemanagement-test.nikecloud.com",
      loadingGroupApi:
          "https://node-ext-loading-group-svc-dev-ap.nodemanagement-test.nikecloud.com",
    }),
    prod = merge(base, {
      specialInstrcnApiUrl:
          "https://node-extn-spcl-instrcn-request-svc-prod.nodemanagement-test.nikecloud.com",
      customerApi:
          "https://node-fr-preprocessor-svc-prod.nodemanagement-test.nikecloud.com",
      carrierHubApi:
          "https://node-fr-preprocessor-svc-prod.nodemanagement-test.nikecloud.com",
      locationMgmtApi:
          "https://node-ext-locn-mgmt-request-svc-prod-eu.nodemanagement-test.nikecloud.com",
      loadingGroupApi:
          "https://node-ext-loading-group-svc-prod-ap.nodemanagement-prod.nikecloud.com",

      isProd: true,
    }),
    test = merge(base, {
      specialInstrcnApiUrl:
          "https://node-extn-spcl-instrcn-request-svc-test.nodemanagement-test.nikecloud.com",
      customerApi:
          "https://node-fr-preprocessor-svc-test.nodemanagement-test.nikecloud.com",
      carrierHubApi:
          "https://node-fr-preprocessor-svc-test.nodemanagement-test.nikecloud.com",
      locationMgmtApi:
          "https://node-ext-locn-mgmt-request-svc-test-eu.nodemanagement-test.nikecloud.com",
      loadingGroupApi:
          "https://node-ext-loading-group-svc-test-ap.nodemanagement-test.nikecloud.com",
    }),
    vpt = merge(base, {
      specialInstrcnApiUrl:
          "https://node-extn-spcl-instrcn-request-svc-vpt.nodemanagement-test.nikecloud.com",
      customerApi:
          "https://node-fr-preprocessor-svc-vpt.nodemanagement-test.nikecloud.com",
      carrierHubApi:
          "https://node-fr-preprocessor-svc-vpt.nodemanagement-test.nikecloud.com",
      locationMgmtApi:
          "https://node-ext-locn-mgmt-request-svc-vpt-eu.nodemanagement-test.nikecloud.com",
      loadingGroupApi:
          "https://node-ext-loading-group-svc-vpt-eu.nodemanagement-test.nikecloud.com",
    }),
    stage = merge(base, {
      specialInstrcnApiUrl:
          "https://node-extn-spcl-instrcn-request-svc-stage.nodemanagement-test.nikecloud.com",
      customerApi:
          "https://node-fr-preprocessor-svc-stage.nodemanagement-test.nikecloud.com",
      carrierHubApi:
          "https://node-fr-preprocessor-svc-stage.nodemanagement-test.nikecloud.com",
      locationMgmtApi:
          "https://node-ext-locn-mgmt-request-svc-stage-eu.nodemanagement-test.nikecloud.com",
      loadingGroupApi:
          "https://node-ext-loading-group-svc-stage-ap.nodemanagement-test.nikecloud.com",
    });

let env = dev;
if (
    window.location.origin.includes("nodeconfig-ui-ap.nike.com") ||
    window.location.origin.includes("nodeconfig-ui-ap-prod.nodemanagement-prod.nikecloud.com")
    //window.location.origin.includes("node-extn-spcl-instrcn-prod.nike.com")
) {
  env = prod;
} else if (
  window.location.origin.includes("nodeconfig-ui-dev.nike.com") ||
  window.location.origin.includes("node-extn-spcl-instrcn-dev.nike.com") ||
  window.location.origin.includes("nodeconfig-ui-ap-dev.nike.com")
) {
  env = dev;
} else if (
 // window.location.origin.includes("nodeconfig-ui-test.nike.com") ||
  window.location.origin.includes("nodeconfig-ui-ap-test.nike.com") ||
  window.location.origin.includes("nodeconfig-ui-ap-test.nodemanagement-test.nikecloud.com")
  //window.location.origin.includes("node-extn-spcl-instrcn-test.nike.com")
) {
  env = test;
} else if (
    window.location.origin.includes("nodeconfig-ui-ap-stage.nike.com") ||
    window.location.origin.includes("nodeconfig-ui-ap-stage.nodemanagement-test.nikecloud.com") ||
    window.location.origin.includes("node-extn-spcl-instrcn-stage.nike.com")
) {
  env = stage;
} else if (
    window.location.origin.includes("nodeconfig-ui-vpt.nike.com") ||
    window.location.origin.includes("node-extn-spcl-instrcn-vpt.nike.com")
) {
  env = vpt;
}
const config = env;
export default config;
